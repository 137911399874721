import { createContext, useContext, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

import styles from "./LoaderContext.module.scss";

const LoaderContext = createContext();

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (state) => setIsLoading(state);

  return (
    <LoaderContext.Provider value={{ isLoading, toggleLoading }}>
      {children}
      {isLoading && (
        <div className={styles.overlay}>
          <BeatLoader color="var(--blue)"></BeatLoader>
        </div>
      )}
    </LoaderContext.Provider>
  );
};
