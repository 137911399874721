import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getUserDataFromPlayFab,
  getUserPublisherDataFromPlayFab,
  updateUserDataToPlayFab,
} from "utils/playFabApi";

import { setUserData, setUserPublisherData } from "appdata/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./Dashboard.module.scss";

import CognitiveIcon from "assets/icons/cognitive.svg";
import NeckIcon from "assets/icons/neck.svg";
import NoDataImg from "assets/img/no-data.webp";
import PaginationCus from "components/PaginationCus/PaginationCus";
import { useLoader } from "contexts/loader-context/LoaderContext";
import { CATEGORIES } from "helps/Categories";
import { UNITS, UNIT_CONFIG } from "helps/UnitConfig";

const _games = [
  {
    name: "puzzles",
    displayName: "Puzzles",
    src: require("assets/img/puzzles_thumb.webp"),
    srcLarge: require("assets/img/puzzles.webp"),
    description: `
    Cách chơi:<br />
    - Mỗi lượt chơi, bạn có thể đặt mục tiêu hoàn thành từ 1 đến tối đa 10 bức tranh để ghép.<br />
    - Mỗi bức tranh được cắt nhỏ thành 5-7 mảnh.<br />
    - Nhiệm vụ của bạn là kéo các mảnh ghép và đặt chúng vào đúng vị trí để hoàn thành bức tranh.<br />
    - Mỗi bức tranh sẽ có một khoảng thời gian giới hạn để hoàn thành, nếu hết thời gian, sẽ chuyển sang bức tranh tiếp theo, cứ thế cho tới hết số tranh mục tiêu mà bạn đã chọn.
  `,
    defaultConfig: {
      timePerTurn: 90,
      countOfTurn: 2,
    },
    link: "/gameplay?game=Puzzles",
    categories: ["all", "cognitive"],
  },
  {
    name: "tmt_A",
    displayName: "TMT-A",
    src: require("assets/img/tmt_A_thumb.webp"),
    srcLarge: require("assets/img/tmt_A.webp"),
    description:
      "TMT (Trail Making Test) is a neurorehabilitation game specifically developed to enhance cognitive function and motor skills. The game involves connecting numbered or lettered dots in a sequence, aimed at improving visual attention, task switching, and mental flexibility. It is commonly used in therapeutic settings for individuals recovering from brain injuries or cognitive impairments, offering a structured way to regain cognitive and motor coordination.",
    defaultConfig: {
      maxNumber: 25,
      duration: 90,
    },
    link: "/gameplay?game=TmtA",
    categories: ["all", "cognitive"],
  },
  {
    name: "tmt_B",
    displayName: "TMT-B",
    src: require("assets/img/tmt_B_thumb.webp"),
    srcLarge: require("assets/img/tmt_B.webp"),
    description:
      "TMT (Trail Making Test) is a neurorehabilitation game specifically developed to enhance cognitive function and motor skills. The game involves connecting numbered or lettered dots in a sequence, aimed at improving visual attention, task switching, and mental flexibility. It is commonly used in therapeutic settings for individuals recovering from brain injuries or cognitive impairments, offering a structured way to regain cognitive and motor coordination.",
    defaultConfig: {
      maxNumber: 25,
      duration: 90,
    },
    link: "/gameplay?game=TmtB",
    categories: ["all", "cognitive"],
  },
  {
    name: "neck_throw",
    displayName: "Head up & down",
    src: require("assets/img/headUp&Down_thumb.webp"),
    srcLarge: require("assets/img/headUp&Down.webp"),
    description:
      "Neck Throw is an interactive game designed to improve neck mobility and coordination through dynamic movements. Players are required to use precise motions to complete tasks, which helps enhance both flexibility and control in the neck region. This game is ideal for rehabilitation, especially for individuals recovering from injuries related to neck stiffness or limited range of motion.",
    defaultConfig: {
      goalHeadUp: 1,
      goalHeadDown: 1,
      durationSecond: 180,
      isHeadDownMode: true,
    },
    link: "/gameplay?game=HeadUp%26Down",
    categories: ["all", "neck"],
  },
  {
    name: "math",
    displayName: "Add & Subtract",
    src: require("assets/img/add&subtract_thumb.webp"),
    srcLarge: require("assets/img/add&subtract.webp"),
    description:
      "Math is an interactive game designed to improve neck mobility and coordination through dynamic movements. Players are required to use precise motions to complete tasks, which helps enhance both flexibility and control in the neck region. This game is ideal for rehabilitation, especially for individuals recovering from injuries related to neck stiffness or limited range of motion.",
    defaultConfig: {
      duration: 120,
      calculationDifficulty: 2,
    },
    link: "/gameplay?game=Add%26subtract",
    categories: ["all", "cognitive"],
  },
];

const categoryIcons = {
  cognitive: CognitiveIcon,
  neck: NeckIcon,
};

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toggleLoading } = useLoader();
  const searchRef = useRef();
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const testRef = useRef(null);

  const authRedux = useSelector((state) => state.authRedux);

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 8;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const games = _games.slice(startIndex, endIndex) ?? [];

  const metadataPage = {
    currentPage: 0,
    numberOfItems: _games.length ?? 0,
    pageSize: 8,
  };

  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES.ALL);
  const [selectedGame, setSelectedGame] = useState(games[0]);
  const [config, setConfig] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [valueSearch, setValueSearch] = useState("");

  const [isConfigGamePopupOpen, setIsConfigGamePopupOpen] = useState(false);

  const playNow = () => {
    toggleLoading(true);
    const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));

    updateUserDataToPlayFab({
      sessionTicket: playFabUser.SessionTicket,
      data: {
        [`${selectedGame.name}_config`]: JSON.stringify(config),
      },
      updateUserDataCallback: (result) => {
        toggleLoading(false);
        if (result.status === "OK") {
          navigate(`${selectedGame.link}&id_user=${playFabUser.Username}`);
        } else {
          toast.error("Error !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      },
    });
  };

  const handleInputChange = (key) => (event) => {
    let { value } = event.target;

    // Kiểm tra nếu key là "isHeadDownMode" thì cần chuyển giá trị thành boolean
    if (key === "isHeadDownMode") {
      value = value === "true"; // Chuyển giá trị thành boolean (true/false)
    } else {
      value = Number(value); // Nếu không, chuyển giá trị thành số
    }
    setConfig((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  };

  const getUserPublisherDataCallback = (result) => {
    if (result.status === "OK") {
      dispatch(setUserPublisherData(result.data.Data));
    } else {
      console.log(result?.errorMessage);
    }
  };
  const getUserDataCallback = (result) => {
    if (result.status === "OK") {
      dispatch(setUserData(result.data.Data));
    } else {
      console.log(result?.errorMessage);
    }
  };

  const handleGameClick = (game) => {
    setSelectedGame(game);
  };

  const updateUserConfig = (userConfig, defaultConfig) => {
    // 1. Thêm các key bị thiếu vào config của người dùng
    const updatedConfig = { ...defaultConfig, ...userConfig };

    // 2. Xóa các key không còn trong defaultConfig (nếu cần)
    Object.keys(updatedConfig).forEach((key) => {
      if (!(key in defaultConfig)) {
        delete updatedConfig[key];
      }
    });

    // 3. Trả về cấu hình đã được cập nhật
    return updatedConfig;
  };

  useEffect(() => {
    if (authRedux.userData) {
      const gameName = selectedGame?.name;
      if (gameName && authRedux.userData.configGames[gameName]) {
        setConfig(authRedux.userData.configGames[gameName]);
        setConfig(
          updateUserConfig(
            authRedux.userData.configGames[gameName],
            selectedGame.defaultConfig
          )
        );
      } else {
        setConfig(selectedGame?.defaultConfig);
      }
    } else {
      setConfig(selectedGame?.defaultConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authRedux, selectedGame, _games]);

  useEffect(() => {
    setSelectedGame(
      games.filter(
        (game) =>
          game.categories.includes(selectedCategory) &&
          game.displayName.toLowerCase().includes(valueSearch.toLowerCase())
      )?.[0] ?? null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, valueSearch]);

  useEffect(() => {
    const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));
    getUserPublisherDataFromPlayFab({
      playFabId: playFabUser.PlayFabId,
      sessionTicket: playFabUser.SessionTicket,
      getUserPublisherDataCallback,
    });
    getUserDataFromPlayFab({
      playFabId: playFabUser.PlayFabId,
      sessionTicket: playFabUser.SessionTicket,
      getUserDataCallback,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.dashboard_wrapper}>
      {!isConfigGamePopupOpen && (
        <div className={styles.tool_box}>
          <div className={`${styles.category_wrapper}`}>
            {Object.keys(CATEGORIES).map((key) => {
              const categoryValue = CATEGORIES[key];
              const Icon = categoryIcons[categoryValue];

              return (
                <span
                  key={key}
                  className={`${
                    selectedCategory === categoryValue
                      ? `text_gradient ${styles.selected_category}`
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedCategory(categoryValue);
                  }}
                >
                  {categoryValue !== "all" && Icon && (
                    <img src={Icon} alt={categoryValue} />
                  )}
                  {t(`game_categories.${categoryValue}`)}
                </span>
              );
            })}
          </div>

          <div
            className={`${styles.search_bar} ${
              isSearchActive ? styles.active : ""
            }`}
            onClick={() => {
              searchRef.current.focus();
              setIsSearchActive(true);
            }}
          >
            <div className={styles.search_icon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001a1 1 0 0 0 .152 1.318l3.85 3.85a1 1 0 0 0 1.415-1.415l-3.85-3.85a1 1 0 0 0-1.318-.152zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
            <input
              ref={searchRef}
              type="text"
              placeholder={t("common_text.search")}
              className={styles.search_input}
              value={valueSearch}
              onChange={(e) => {
                const newValue = e.target.value;
                setValueSearch(newValue);
                setIsSearchActive(newValue.trim() !== "");
              }}
              onFocus={() => setIsSearchActive(true)}
              onBlur={() => {
                if (valueSearch.trim() === "") {
                  setIsSearchActive(false);
                }
              }}
            />
          </div>
        </div>
      )}
      {!isConfigGamePopupOpen && (
        <div className={styles.dashboard_container}>
          {selectedGame && (
            <div className={styles.game_list}>
              {games
                .filter(
                  (game) =>
                    game.categories.includes(selectedCategory) &&
                    game.displayName
                      .toLowerCase()
                      .includes(valueSearch.toLowerCase())
                )
                .map((game, index) => (
                  <div
                    key={index}
                    className={`${styles.game_item} ${
                      selectedGame?.name === game.name ? styles.selected : ""
                    }`}
                    onClick={() => handleGameClick(game)}
                  >
                    <div className={styles.image_container}>
                      <img
                        src={game.src}
                        alt={game.name}
                        className={styles.game_image}
                      />
                      <div className={styles.overlay}>
                        <button
                          className={`${styles.config_button} rounded`}
                          onClick={() => {
                            handleGameClick(game);
                            setIsConfigGamePopupOpen(true);
                          }}
                        >
                          {t("common_text.configPlay")}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`
                    ${selectedGame?.name === game.name ? "text_gradient" : ""}
                    ${styles.game_name}`}
                    >
                      {game.displayName}
                    </div>
                  </div>
                ))}
            </div>
          )}
          <PaginationCus
            metadataPage={metadataPage}
            onClick={(pageParam) => {
              setCurrentPage(pageParam.page);
            }}
            styles={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1vw",
            }}
          />
          {!selectedGame && (
            <div className={styles.table_nodata}>
              <img src={NoDataImg} alt="nodata"></img>
              <span>{t("common_text.noData")}</span>
            </div>
          )}
        </div>
      )}

      {isConfigGamePopupOpen && (
        <div className={styles.config_game_container}>
          <div ref={leftRef} className={styles.left}>
            <div className={styles.config_description}>
              <h2 className="text_gradient">{t("common_text.description")}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t(`game_description.${selectedGame.name}`),
                }}
              />
            </div>

            <div className={styles.config_tab}>
              <h2 className="text_gradient">
                {t("common_text.configuration")}
              </h2>

              <div className={styles.config_scroll}>
                {selectedGame.name === "neck_throw" && (
                  <>
                    <div className={styles.config_item}>
                      <label>{t(`game_config.durationSecond`)}</label>
                      <div className={styles.input_wrapper}>
                        <input
                          type="number"
                          value={config.durationSecond}
                          onChange={handleInputChange("durationSecond")}
                          className="size_medium"
                        />
                        <span className={styles.unit}>{t("unit.seconds")}</span>
                      </div>
                    </div>
                    <div className={styles.config_item}>
                      <label>{t(`game_config.isHeadDownMode`)}</label>
                      <div className={styles.input_wrapper}>
                        <select
                          value={config.isHeadDownMode}
                          onChange={handleInputChange("isHeadDownMode")}
                          className="size_medium"
                        >
                          <option value={true}>
                            {t("game_config.goalHeadDown")}
                          </option>
                          <option value={false}>
                            {t("game_config.goalHeadUp")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.config_item}>
                      <label>{t(`game_config.repetitions`)}</label>
                      <div className={styles.input_wrapper}>
                        <input
                          type="number"
                          value={
                            config.isHeadDownMode
                              ? config.goalHeadDown
                              : config.goalHeadUp
                          }
                          onChange={handleInputChange(
                            config.isHeadDownMode
                              ? "goalHeadDown"
                              : "goalHeadUp"
                          )}
                          className="size_medium"
                          min={1}
                        />
                        <span className={styles.unit}>{t("unit.count")}</span>
                      </div>
                    </div>
                  </>
                )}
                {selectedGame.name !== "neck_throw" &&
                  Object.entries(config).map(([key, value]) => {
                    if (key === "isHeadDownMode") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value}
                              onChange={handleInputChange(key)}
                              className="size_medium"
                            >
                              <option value={true}>
                                {t("game_config.goalHeadDown")}
                              </option>
                              <option value={false}>
                                {t("game_config.goalHeadUp")}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }
                    if (key === "calculationDifficulty") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <select
                              value={value}
                              onChange={handleInputChange(key)}
                              className="size_medium"
                            >
                              <option value={1}>
                                {`${t("game_config.calculationDifficulty")} 1`}
                              </option>
                              <option value={2}>
                                {`${t("game_config.calculationDifficulty")} 2`}
                              </option>
                              <option value={3}>
                                {`${t("game_config.calculationDifficulty")} 3`}
                              </option>
                            </select>
                          </div>
                        </div>
                      );
                    }
                    if (key !== "timePerTurn") {
                      return (
                        <div className={styles.config_item} key={key}>
                          <label>{t(`game_config.${key}`)}</label>
                          <div className={styles.input_wrapper}>
                            <input
                              type="number"
                              value={value}
                              onChange={handleInputChange(key)}
                              className="size_medium"
                              {...((selectedGame.name === "tmt_A" ||
                                selectedGame.name === "tmt_B") &&
                              key === "maxNumber"
                                ? { min: 2, max: 50 }
                                : selectedGame.name === "math" &&
                                  key === "calculationDifficulty"
                                ? { min: 1, max: 3 }
                                : {})}
                            />
                            <span className={styles.unit}>
                              {UNIT_CONFIG[key] !== UNITS.NONE &&
                                `(${t(`unit.${UNIT_CONFIG[key]}`)})`}
                            </span>
                          </div>
                        </div>
                      );
                    }

                    return null;
                  })}
              </div>
            </div>
          </div>

          <div ref={rightRef} className={styles.right}>
            <div className={styles.overlay}></div>
            <img ref={testRef} src={selectedGame.srcLarge} alt="img" />
            <div className={styles.float}>
              <span className={styles.gameName}>
                {selectedGame.displayName}
              </span>
              <button
                className={`${styles.play_button} button_gradient_blue rounded size_large`}
                onClick={playNow}
              >
                {t("common_text.playNow")}
              </button>
            </div>
          </div>
          <button
            className={`${styles.close_btn} size_1 rounded`}
            onClick={() => {
              setIsConfigGamePopupOpen(false);
            }}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
