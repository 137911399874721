import MasterLayout from "layouts/MasterLayout";
import Dashboard from "pages/dashboard/Dashboard";
import GamePlay from "pages/game/GamePlay";
import Login from "pages/login/Login";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";

// Hàm kiểm tra nếu user đã đăng nhập
const isAuthenticated = () => {
  return !!localStorage.getItem("playFabUser"); // Trả về true nếu có playFabUser
};

// ProtectedRoute component để kiểm tra quyền truy cập
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Chuyển hướng đến login nếu không có playFabUser
    return <Navigate to="/login" />;
  }

  // Hiển thị nội dung nếu đã đăng nhập
  return children;
};

function AppRoutes() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const bodyElement = document.body;

    if (i18n.language === "ja") {
      bodyElement.classList.add("japanese-font");
    } else {
      bodyElement.classList.remove("japanese-font");
    }
  }, [i18n.language]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("vtg_la") ?? "ja");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/gameplay" element={<GamePlay />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
