export function getUserDataFromPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  playFabId,
  sessionTicket,
  keys = [],
  getUserDataCallback,
}) {
  const request = {
    TitleId: titleId,
    PlayFabId: playFabId,
    Keys: keys,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/GetUserData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof getUserDataCallback === "function") {
        getUserDataCallback(result);
      }
    });
}

export function getUserPublisherDataFromPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  playFabId,
  sessionTicket,
  keys = [],
  getUserPublisherDataCallback,
}) {
  const request = {
    TitleId: titleId,
    PlayFabId: playFabId,
    Keys: keys,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/GetUserPublisherData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof getUserPublisherDataCallback === "function") {
        getUserPublisherDataCallback(result);
      }
    });
}

export function updateUserDataToPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  sessionTicket,
  data = {},
  keysToRemove = [],
  permission = "public",
  updateUserDataCallback,
}) {
  const request = {
    Data: data,
    KeysToRemove: keysToRemove,
    Permission: permission,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/UpdateUserData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof updateUserDataCallback === "function") {
        updateUserDataCallback(result);
      }
    });
}

export function updateUserPublisherDataToPlayFab({
  titleId = window._env_.REACT_APP_TITLE_ID,
  sessionTicket,
  data = {},
  keysToRemove = [],
  permission = "public",
  customTags = {},
  updateUserPublisherDataCallback,
}) {
  const request = {
    CustomTags: customTags,
    Data: data,
    KeysToRemove: keysToRemove,
    Permission: permission,
  };

  fetch(`https://${titleId}.playfabapi.com/Client/UpdateUserPublisherData`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Authorization": sessionTicket,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof updateUserPublisherDataCallback === "function") {
        updateUserPublisherDataCallback(result);
      }
    });
}

export function applyToGroup({
  titleId = window._env_.REACT_APP_TITLE_ID,
  entityToken,
  groupId,
  autoAcceptOutstandingInvite = true,
  entity = null,
  applyCallback,
}) {
  const request = {
    Group: {
      Id: groupId,
      Type: "group",
    },
    AutoAcceptOutstandingInvite: autoAcceptOutstandingInvite,
  };

  if (entity) {
    request.Entity = entity;
  }

  fetch(`https://${titleId}.playfabapi.com/Group/ApplyToGroup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-EntityToken": entityToken,
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((result) => {
      if (typeof applyCallback === "function") {
        applyCallback(result);
      }
    });
}
