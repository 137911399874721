import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { updateUserPublisherDataToPlayFab } from "utils/playFabApi";
import { useLoader } from "contexts/loader-context/LoaderContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";

import SwitchUserImg from "assets/img/switch-user.webp";
import UserInformationImg from "assets/img/user-information.webp";

import styles from "./Popup.module.scss";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const { t } = useTranslation();
  const { toggleLoading } = useLoader();
  const navigate = useNavigate();

  const [customId, setCustomId] = useState("");
  const authRedux = useSelector((state) => state.authRedux);

  const [isSwitchUserPopupOpen, setIsSwitchUserPopupOpen] = useState(false);
  const [isUserInfomationPopupOpen, setIsUserInfomationPopupOpen] =
    useState(false);

  const [userInfo, setUserInfo] = useState(
    authRedux.userPublisherData ?? {
      name: { Value: "" },
      phone: { Value: "" },
      gender: { Value: "Nam" },
      age: { Value: "" },
    }
  );

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (
      userInfo.name !== "" ||
      userInfo.phone !== "" ||
      userInfo.gender !== "Nam" ||
      userInfo.age !== ""
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [userInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: { Value: value },
    }));
  };

  const updateUserPublisherDataCallback = (result) => {
    if (result.status === "OK") {
      toggleLoading(false);
      setIsUserInfomationPopupOpen(false);
    } else {
      toast.error("Error !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSaveUserPublisherData = () => {
    if (isChanged) {
      toggleLoading(true);
      const transformedUserInfo = Object.fromEntries(
        Object.entries(userInfo).map(([key, { Value }]) => [key, Value])
      );
      const playFabUser = JSON.parse(localStorage.getItem("playFabUser"));
      updateUserPublisherDataToPlayFab({
        sessionTicket: playFabUser.SessionTicket,
        data: transformedUserInfo,
        updateUserPublisherDataCallback,
      });
    }
  };

  const handleSwitchingUser = () => {
    toggleLoading(true);
    const loginRequest = {
      CustomId: customId,
    };
    const loginCallback = (result, error) => {
      if (result !== null) {
        toggleLoading(false);
        const userData = {
          PlayFabId: result.data.PlayFabId,
          SessionTicket: result.data.SessionTicket,
          EntityToken: result.data.EntityToken.EntityToken,
          Username: customId,
        };

        localStorage.setItem("playFabUser", JSON.stringify(userData));
        navigate("/dashboard");
        window.location.reload();
      } else if (error !== null) {
        toggleLoading(false);
        toast.error("Error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    };
    window.PlayFabClientSDK.LoginWithCustomID(loginRequest, loginCallback);
  };

  useEffect(() => {
    if (!authRedux.userPublisherData) return;
    setUserInfo(authRedux.userPublisherData);
  }, [authRedux]);

  const contextValue = useMemo(
    () => ({
      isSwitchUserPopupOpen,
      setIsSwitchUserPopupOpen,
      isUserInfomationPopupOpen,
      setIsUserInfomationPopupOpen,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSwitchUserPopupOpen, isUserInfomationPopupOpen]
  );

  return (
    <PopupContext.Provider value={contextValue}>
      {children}

      <Popup
        style={{ zIndex: 1001 }}
        open={isSwitchUserPopupOpen}
        modal
        onClose={() => {
          setIsSwitchUserPopupOpen(false);
        }}
        closeOnDocumentClick
      >
        <div className={styles.customSwitchUserPopup}>
          <button
            className={`${styles.close_btn} size_1`}
            onClick={() => {
              setIsSwitchUserPopupOpen(false);
            }}
          >
            &times;
          </button>

          <div className={styles.switch_user_popup_content}>
            <img alt="switch-user" src={SwitchUserImg}></img>
            <div className={styles.side}>
              <div className={`size_1 text_gradient ${styles.title}`}>
                {t("common_text.userSwitching")}
              </div>
              <p>{`"${t("header.changeCharacterMessage")}"`}</p>
              <p>{`"${t("header.discoverGameMessage")}"`}</p>

              <div className={styles.input_box}>
                <input
                  placeholder={t("common_text.enterThePatientCode")}
                  value={customId}
                  onChange={(e) => setCustomId(e.target.value)}
                />
              </div>

              <button
                className={`${styles.switching_btn} button_gradient`}
                onClick={handleSwitchingUser}
              >
                {t("common_text.switching")}
              </button>
            </div>
          </div>
        </div>
      </Popup>

      <Popup
        style={{ zIndex: 1001 }}
        open={isUserInfomationPopupOpen}
        modal
        onClose={() => {
          setIsUserInfomationPopupOpen(false);
        }}
        closeOnDocumentClick
      >
        <div className={styles.customUserInformationPopup}>
          <button
            className={`${styles.close_btn} size_1`}
            onClick={() => {
              setIsUserInfomationPopupOpen(false);
            }}
          >
            &times;
          </button>

          <div className={styles.user_infor_popup_content}>
            <div className={styles.left_side}>
              <img alt="user-infomation" src={UserInformationImg}></img>
            </div>
            <div className={styles.side}>
              <div className={`size_1 text_gradient ${styles.title}`}>
                {t("common_text.userInformation")}
              </div>
              <p>{`"${t("header.personalInfoRequestMessage")}"`}</p>

              <div className={styles.input_row}>
                <input
                  type="text"
                  name="name"
                  value={userInfo.name?.Value || ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.fullName")}`}
                />
              </div>
              <div className={styles.input_row}>
                <input
                  type="text"
                  name="phone"
                  value={userInfo.phone?.Value || ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.phoneNumber")}`}
                />
              </div>
              <div className={styles.input_row}>
                <select
                  name="gender"
                  value={userInfo.gender?.Value || ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.gender")}`}
                >
                  <option value="male">{t("common_text.male")}</option>
                  <option value="female">{t("common_text.female")}</option>
                </select>
              </div>
              <div className={styles.input_row}>
                <input
                  type="number"
                  name="age"
                  value={userInfo.age?.Value || ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.age")}`}
                />
              </div>

              <button
                className={`${styles.submit_btn} ${
                  isChanged ? styles.active : styles.inactive
                } rounded button_gradient`}
                onClick={handleSaveUserPublisherData}
                disabled={!isChanged}
              >
                {t("button.save")}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </PopupContext.Provider>
  );
};

PopupProvider.propTypes = {
  children: PropTypes.any,
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopup must be used within a PopupProvider");
  }
  return context;
};
