import { useRef } from "react";
import ZoomIcon from "assets/icons/zoom.png";
import styles from "./GamePlay.module.scss";

function GamePlay() {
  const searchParams = new URLSearchParams(window.location.search);
  const game = searchParams.get("game");
  const id_user = searchParams.get("id_user");

  const iframeRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      // Enter fullscreen
      if (iframeRef.current) {
        if (iframeRef.current.requestFullscreen) {
          iframeRef.current.requestFullscreen();
        } else if (iframeRef.current.mozRequestFullScreen) {
          // Firefox
          iframeRef.current.mozRequestFullScreen();
        } else if (iframeRef.current.webkitRequestFullscreen) {
          // Chrome, Safari, and Opera
          iframeRef.current.webkitRequestFullscreen();
        } else if (iframeRef.current.msRequestFullscreen) {
          // IE/Edge
          iframeRef.current.msRequestFullscreen();
        }
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        padding: "2vw 4vw",
        position: "relative",
      }}
    >
      <div
        style={{
          borderRadius: "1vw",
          display: "flex",
          overflow: "hidden",
          flex: "1",
          justifyContent: "center",
        }}
        ref={iframeRef}
      >
        <iframe
          title="WebGL Game"
          src={`/${game}/index.html?id_user=${id_user}`}
          style={{
            border: "none",
            aspectRatio: "16/9",
            borderRadius: "1vw",
          }}
          allowFullScreen
        />

        <button className={styles.zoom_btn} onClick={toggleFullScreen}>
          <img src={ZoomIcon} alt="Avatar" />
        </button>
      </div>
    </div>
  );
}

export default GamePlay;
