import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  userPublisherData: null,
  userData: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserPublisherData: (state, action) => {
      state.userPublisherData = action.payload;
    },
    setUserData: (state, action) => {
      const payload = action.payload;
      const configData = {};
      const historyData = {};

      Object.keys(payload).forEach((key) => {
        if (key.endsWith("_config")) {
          if (payload[key] && payload[key].Value) {
            configData[key.replace("_config", "")] = JSON.parse(
              payload[key].Value
            );
          } else {
            // Xử lý trường hợp payload[key] hoặc payload[key].Value không tồn tại
            configData[key.replace("_config", "")] = null; // hoặc giá trị mặc định bạn muốn
          }
        } else if (key.endsWith("_result")) {
          if (payload[key] && payload[key].Value) {
            historyData[key.replace("_result", "")] = JSON.parse(
              payload[key].Value
            );
          } else {
            // Xử lý trường hợp payload[key] hoặc payload[key].Value không tồn tại
            historyData[key.replace("_result", "")] = null; // hoặc giá trị mặc định bạn muốn
          }
        }
      });

      const userData = {
        configGames: {
          ...configData,
        },
        historyGames: {
          ...historyData,
        },
      };

      if (payload.patients?.Value !== undefined) {
        try {
          userData.patients = JSON.parse(payload.patients.Value);
        } catch (error) {
          console.error("Error parsing patients data:", error);
          // Bạn có thể quyết định xử lý lỗi khác ở đây nếu cần
        }
      }

      state.userData = userData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {})
      .addCase(login.fulfilled, (state, action) => {
        state.me = action.payload;
      })
      .addCase(login.rejected, (state, action) => {});
  },
});

export const login = createAsyncThunk("auth/login", async (params) => {
  try {
    const { loginId } = params;
    const response = await axios.post("/authenticate/login", {
      loginId: loginId,
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
});

export const { setUserPublisherData, setUserData } = authSlice.actions;

export default authSlice.reducer;
